import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { IoMdClipboard, IoMdTrash } from 'react-icons/io'
import { FaRegFileAlt, FaSignature, FaCheck, FaHashtag } from 'react-icons/fa'
import { useTheme } from '@mui/styles'
import moment from 'moment'
import ReactQuill from 'react-quill'
import Rating from '@mui/lab/Rating'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import ReviewServiceFactory, { RadarServices } from '../../../services'
import ButtonSpinner from '../../common/ButtonSpinner'
import SecondaryButton from './SecondaryButton'
import ReponseTemplates from './Modals/ResponseTemplates'
import SignatureTemplates from './Modals/SignatureTemplates'
import EmailTemplates from './Modals/EmailTemplates'
import PublishResponse from './Modals/PublishResponse'
import EmailButton from './EmailButton'
import EscalateButton from './EscalateButton'
import ResolutionButtions from './ResolutionButtons'
import ReplyTimeLine from './Timeline'
import {
  useStyles,
  Container,
  Subheader,
  ReviewText,
  ReviewTitle,
  ReviewContent,
  NotificationBar,
  ResolutionContainer,
  Toolbar,
  Editor,
} from './ReviewResponse.S'

import {
  reviewSaveDraftStartedAction,
  reviewSaveActionStartedAction,
  reviewSaveReviewCompletedAction,
  SaveActionTypes,
  reviewDisplayReasonCommentAction,
} from '../../../store/actions/review'

import { ActionIDs } from '../../../store/actions/reviews'
import { addNotificationAction, NotificationType } from '../../../store/actions/notifications'
import Button from '../../common/Button'
import ClearTemplateConfirm from './ClearTemplateConfirm'
import {DisputationMap} from "./EscalateButton.S";

const ActionTypes = {
  ESCALATE: 'Escalate',
  DEESCALATE: 'Deescalate',
  MARK_DISPUTED: 'MarkDisputed',
  MARK_DISPUTE_ACCEPTED: 'MarkDisputeAccepted',
  MARK_DISPUTE_DENIED: 'MarkDisputeDenied',
  DONOTRESPOND: 'DoNotRespond',
  MARK_RESPONSE_PROPOSED: 'MarkResponseProposed',
  MARK_MANUALLY_POSTED: 'MarkManuallyPosted',
  MARK_PUBLISHED_DIRECTLY: 'MarkPublishedDirectly',
  REJECT_PROPOSED_RESPONSE: 'RejectProposedResponse',
  APPROVE_PROPOSED_RESPONSE: 'ApproveProposedResponse',
  CLOSE: 'Close',
}

function ReviewResponse(props) {
  const {
    review,
    activeFolderId,
    updateStarted,
    updatedReview,
    saveDraftRequest,
    addNotification,
    saveActionRequest,
    actionUpdateStarted,
    actionUpdateFor,
    saveReviewCompleted,
    handleReviewDisplayReasonComment,
  } = props

  const actions = [
    ActionTypes.ESCALATE,
    ActionTypes.DEESCALATE,
    ActionTypes.MARK_DISPUTED,
    ActionTypes.MARK_DISPUTE_ACCEPTED,
    ActionTypes.MARK_DISPUTE_DENIED,
    ActionTypes.DONOTRESPOND,
    ActionTypes.MARK_RESPONSE_PROPOSED,
    ActionTypes.MARK_MANUALLY_POSTED,
    ActionTypes.MARK_PUBLISHED_DIRECTLY,
    ActionTypes.REJECT_PROPOSED_RESPONSE,
    ActionTypes.APPROVE_PROPOSED_RESPONSE,
    ActionTypes.CLOSE,
  ]

  const [draft, setDraft] = useState(null)
  const [enableSaveDraft, setEnableSaveDraft] = useState(false)
  const [showResponseTemplates, setShowResponseTemplates] = useState(false)
  const [showSignatureTemplates, setShowSignatureTemplates] = useState(false)
  const [showEmailTemplates, setShowEmailTemplates] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [emailTemplateId, setEmailTemplateId] = useState(null)
  const theme = useTheme()
  const classes = useStyles()
  const [selectedTemplateId,setSelectedTemplateId] = useState(null)
  const [clearStarted,setClearStarted] = useState(false)
  const [clearTemplateConfirmationOpen,setClearTemplateConfirmationOpen] = useState(false)
  const saveButtonStyle = { color: '#FFF', paddingTop: '1px' }
  const quillStyle = {
    toolbar: false,
    clipboard: {
      matchVisual: false,
    },
  }

  const generateSaveDraftText = () => {
    if (!canEditReview()) return <span>Locked</span>
    else if (!updatedReview) return 'Save Draft'
    else
      return (
        <>
          <span>Saved</span>
          <FaCheck />{' '}
        </>
      )
  }

  const generateNotificationHeader = () => {
    if (review.lastModifiedBy) {
      return (
        <NotificationBar>
          <p>
            Last modification by {review.lastModifiedBy.user.fullname}&nbsp;on&nbsp;
            {moment(review.lastModifiedBy.modifiedDate).format('dddd, MMMM Do YYYY h:mm a')}
          </p>
        </NotificationBar>
      )
    }
  }

  const canEditReview = () => review.status !== 'Closed' && review.status !== 'DoNotRespond'

  const hasReviewContentOrTitle = () =>
    (review.reviewText && review.reviewText.length > 0) ||
    (review.reviewTitle && review.reviewTitle.length > 0)

  const saveReview = async payload => {
    saveDraftRequest()

    let service = ReviewServiceFactory.create(RadarServices.Reviews)
    let result = await service.saveReview(review.reviewId, payload)
    if (result.Ok) {
      addNotification('Review Saved!', NotificationType.SUCCESS_NOTIFICATION)
    } else {
      addNotification('Failed to save review!', NotificationType.ERROR_NOTIFICATION)
    }

    saveReviewCompleted(result.Ok ? result.Content : review)
  }

  const saveReviewAction = async (actionId, saveAction, payload) => {
    saveActionRequest(actionId)

    let service = ReviewServiceFactory.create(RadarServices.Reviews)
    let result = await service.saveReviewAction(review.reviewId, payload)
    if (result.Ok) {
      let msg = actionId === ActionTypes.MARK_PUBLISHED_DIRECTLY ? 'Published Review!' : 'Review Saved!'
      setShowPublishModal(false)
      addNotification(msg, NotificationType.SUCCESS_NOTIFICATION)
    } else {
      if (actionId === ActionTypes.MARK_PUBLISHED_DIRECTLY) {
        switch (result.Content.responseCode) {
          case 403:
            addNotification('Failed. Review already has a response', NotificationType.ERROR_NOTIFICATION)
            break
          case 404:
            addNotification('Failed. Review no longer exists', NotificationType.ERROR_NOTIFICATION)
            break
          case 500:
            addNotification(
              'Failed. An unexpected error occurred publishing response contact IT',
              NotificationType.ERROR_NOTIFICATION
            )
            break
          default:
            addNotification(
              'Failed. An error occurred publishing review to site please try again',
              NotificationType.ERROR_NOTIFICATION
            )
            break
        }
      } else {
        addNotification('Failed to save review!', NotificationType.ERROR_NOTIFICATION)
      }
    }

    saveReviewCompleted(result.Ok ? result.Content.review : review, result.Ok ? saveAction : null)
  }

  const handleOpenResponseTemplateClick = () => setShowResponseTemplates(true)
  const clearTemplate=async () => {
    setClearTemplateConfirmationOpen(false)
    setClearStarted(true)
    let service = ReviewServiceFactory.create(RadarServices.ResponseTemplates),
      result = await service.undoUsage(selectedTemplateId, review.client.clientId)
    setClearStarted(false)
    if (result.Ok) {
      setDraft(null)
      setSelectedTemplateId(null)
    } else {
      addNotification('Failed to clear the template!', NotificationType.ERROR_NOTIFICATION)
    }
  }
  const handleClearTemplateClick = async () => {
    setClearTemplateConfirmationOpen(true)
  }
  const handleResponseItemSelected = (text,id) => {
    // We replace new line with html style breaks because the editor
    // ignores normal line breaks.
    setSelectedTemplateId(id)
    setDraft((draft + text).replaceAll('\n', '<br/>'))
    setEnableSaveDraft(true)
  }

  const handleOpenSignatureTemplateClick = () => setShowSignatureTemplates(true)

  const handleSignatureItemSelected = text => {
    // We replace new line with html style breaks because the editor
    // ignores normal line breaks.
    setDraft(`${draft}<br/><br/>${text.replaceAll('\n', '<br/>')}`)
    setEnableSaveDraft(true)
  }

  const handleOpenEmailTemplateClick = notification => {
    setEmailTemplateId(notification.value)
    setShowEmailTemplates(true)
  }

  const handleResponseChange = (data, delta, source, editor) => {
    if (canEditReview()) {
      let len = editor.getText().length
      if (len <= 1) data = ''
      setDraft(data)
      setEnableSaveDraft(len > 1)
    }
  }

  const handleSaveDraftClick = () => {
      if (!updateStarted && draft.length > 0) {
          setSelectedTemplateId(null)
      saveReview({
        responseText: draft,
      })
    }
  }

  const handleCopyToClipboard = () => {
    var content = draft
      .replace(/<br(.|\s)?>|<\/p>/gi, '\n')
      .replace(/<[^>]+>|&nbsp;/gi, '')
      .replace('&amp;', '&')
    navigator.clipboard.writeText(content)
    addNotification('Response copied to clipboard!', NotificationType.SUCCESS_NOTIFICATION)
  }

  const handlePublishResponse = respondent => {
    saveReviewAction(ActionTypes.MARK_PUBLISHED_DIRECTLY, SaveActionTypes.REMOVE_FROM_QUEUE, {
      publishedDirectly: true,
      clientId: review.client.clientId,
      respondent,
    })
  }

  const handleActionButtonsClick = (resolution, reason) => {
    let payload = {},
      saveAction = null

    switch (resolution) {
      case ActionTypes.ESCALATE:
        saveAction = SaveActionTypes.ADD_TO_ESCALATED
        payload.escalated = true
        break

      case ActionTypes.DEESCALATE:
        saveAction = SaveActionTypes.REMOVE_FROM_ESCALATED
        payload.escalated = false
        break

      case ActionTypes.MARK_DISPUTED:
        payload.disputed = true
        saveAction = SaveActionTypes.ADD_TO_DISPUTED
        break

      case ActionTypes.MARK_DISPUTE_ACCEPTED:
        payload.disputeAccepted = true
        break

      case ActionTypes.MARK_DISPUTE_DENIED:
        payload.disputeDenied = true
        saveAction = SaveActionTypes.REMOVE_FROM_DISPUTED
        break

      case ActionTypes.DONOTRESPOND:
        payload.doNotRespond = true
        payload.reason = reason
        saveAction = SaveActionTypes.REMOVE_FROM_QUEUE
        break

      case ActionTypes.MARK_RESPONSE_PROPOSED:
        payload.responseProposed = true
        saveAction = SaveActionTypes.ADD_TO_PROPOSED
        break

      case ActionTypes.MARK_MANUALLY_POSTED:
        payload.manuallyPosted = true
        saveAction = SaveActionTypes.REMOVE_FROM_QUEUE
        break

      case ActionTypes.REJECT_PROPOSED_RESPONSE:
        payload.rejectProposed = true
        saveAction =
          activeFolderId === ActionIDs.PROPOSED
            ? SaveActionTypes.REMOVE_FROM_PROPOSED
            : SaveActionTypes.REMOVE_FROM_EXPIRED_PROPOSALS
        break

      case ActionTypes.APPROVE_PROPOSED_RESPONSE:
        payload.approveProposed = true
        saveAction =
          activeFolderId === ActionIDs.PROPOSED
            ? SaveActionTypes.REMOVE_FROM_PROPOSED
            : SaveActionTypes.REMOVE_FROM_EXPIRED_PROPOSALS
        break

      case ActionTypes.CLOSE:
        payload.close = true
        saveAction = SaveActionTypes.REMOVE_FROM_QUEUE
        break

      case ActionTypes.MARK_PUBLISHED_DIRECTLY:
        setShowPublishModal(true)
        return

      default:
        console.log(resolution)
        return
    }

    saveReviewAction(resolution, saveAction, payload)
  }
  const handleDisputeButtonsClick = async (resolution) => {
    let payload = {},
        saveAction = null
    saveAction = SaveActionTypes.ADD_TO_ESCALATED
    resolution = DisputationMap.Escalate.text
    payload.escalated = true
    await saveReviewAction(resolution, saveAction, payload)
    payload = {}
    saveAction = SaveActionTypes.ADD_TO_DISPUTED
    resolution = DisputationMap.MarkDisputed.text
    payload.disputed = true
    saveReviewAction(resolution, saveAction, payload)
  }
  useEffect(() => {
    // We replace new line with html style breaks because the editor
    // ignores normal line breaks.
      setSelectedTemplateId(null)
    setDraft((review.responseText || '').replaceAll('\n', '<br/>'))
      setEnableSaveDraft(canEditReview() && review.responseText?.length > 0)
  }, [review.reviewId, review.status])
  return (
    <>
      {generateNotificationHeader()}
      <Container style={{ marginTop: '24px' }}>
        <header>
          <div className='row'>
            <div className='col-md-auto '>
              <Typography variant='h5'>{review.reviewerName}</Typography>
              <Subheader>{moment(review.reviewDate).format('dddd, MMMM Do YYYY')}</Subheader>
            </div>
            <div className='col'>
              <div className='d-flex justify-content-end mt-n1'>
                <Rating value={review.reviewRating} precision={0.1} readOnly />
              </div>
              <Subheader className='d-flex justify-content-end'>{review.reviewSite}</Subheader>
            </div>
          </div>
          {hasReviewContentOrTitle() && (
            <ReviewContent className='d-flex'>
              <ReviewText
                className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}>
                <ReviewTitle>{review.reviewTitle}</ReviewTitle>
                {review.reviewText}
              </ReviewText>
            </ReviewContent>
          )}
          <div
            className={classes.chips}
            style={{ display: review.keywordCategories.length > 0 ? 'flex' : 'none' }}>
            {review.keywordCategories
              .sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name === b.name) return 0
                return 1
              })
              .map(category => (
                <Chip
                  classes={{
                    root: classes.chip,
                    colorPrimary: classes.chipColor,
                  }}
                  key={category.id}
                  icon={<FaHashtag className={classes.avatar} />}
                  label={category.name}
                  color='primary'
                  size='small'
                />
              ))}
          </div>
          <ReplyTimeLine review={review} />
        </header>
        <div className='d-flex flex-column rounded'>
          <Editor>
            <ReactQuill
              id='reviewEditor'
              name='reviewEditor'
              modules={quillStyle}
              readOnly={!canEditReview()}
              value={draft}
              placeholder='Add Reply'
              onChange={handleResponseChange}
              style={{
                height: 'auto',
                cursor: canEditReview() ? 'default' : 'not-allowed',
              }}
            />
          </Editor>
          
           {selectedTemplateId && <ButtonSpinner
            style={{ marginLeft: '8px', marginTop:'-20px',
              color: '#FFF',width:270,position:"relative", display:'flex',alignSelf:'start',
            }}
            spin={clearStarted}
            variant='contained'
            color='info'
            onClick={handleClearTemplateClick}>
            <IoMdTrash /> &nbsp; Clear Response Template
          </ButtonSpinner>}
          <Toolbar className='p-2'>
            <div className='d-flex w-100 align-items-center'>
              <div className='d-flex align-items-center'>
                <SecondaryButton
                  disabled={!canEditReview()}
                  toolTip='Copy Draft to Clipboard.'
                  onClick={handleCopyToClipboard}>
                  <IoMdClipboard />
                </SecondaryButton>
                <SecondaryButton
                  disabled={!canEditReview()}
                  toolTip='Click to choose a Response Template.'
                  onClick={handleOpenResponseTemplateClick}>
                  <FaRegFileAlt />
                </SecondaryButton>
                <SecondaryButton
                  disabled={!canEditReview()}
                  toolTip='Click to choose a signature.'
                  onClick={handleOpenSignatureTemplateClick}>
                  <FaSignature />
                </SecondaryButton>
                <div
                  className={`margin-t-8  email-template-button das-disable ${
                    ['Undrafted', 'DoNotRespond', 'Closed'].includes(review.status) && 'das-disabled'
                  }`}>
                  <EmailButton review={review} onClick={handleOpenEmailTemplateClick} />
                </div>
              </div>
              <div className='ml-auto d-flex align-items-center'>
                <div className='small-text text-secondary pr-2 '>{`${draft?.length ?? 0} characters`}</div>
                <ButtonSpinner
                  style={{ color: '#FFF' }}
                  disableElevation
                  variant='contained'
                  color='primary'
                  spin={updateStarted && !actionUpdateStarted}
                  spinContent={<div style={saveButtonStyle}>Saving..</div>}
                  disabled={!enableSaveDraft}
                  onClick={handleSaveDraftClick}>
                  {generateSaveDraftText()}
                </ButtonSpinner>
              </div>
            </div>
          </Toolbar>
        </div>
        <div className='d-flex pt-3 flex-column'>
          <ResolutionContainer className='no-gutters' disabled={review.status === 'Closed'}>
            <EscalateButton
              review={review}
              updateStarted={actionUpdateStarted && actions.includes(actionUpdateFor)}
              onClick={handleActionButtonsClick}
              onDisputeClick = {handleDisputeButtonsClick}
            />
            <ResolutionButtions
              review={review}
              updateStarted={actionUpdateStarted && actions.includes(actionUpdateFor)}
              onResolutionButtonClick={handleActionButtonsClick}
            />
          </ResolutionContainer>
        </div>
      </Container>
      {showResponseTemplates && (
        <ReponseTemplates
          show={showResponseTemplates}
          onClose={() => setShowResponseTemplates(false)}
          onTemplateItemSelected={handleResponseItemSelected}
        />
      )}
      {showSignatureTemplates && (
        <SignatureTemplates
          show={showSignatureTemplates}
          onClose={() => setShowSignatureTemplates(false)}
          onTemplateItemSelected={handleSignatureItemSelected}
        />
      )}
      {showEmailTemplates && (
        <EmailTemplates
          templateId={emailTemplateId}
          show={showEmailTemplates}
          draftedResponse={draft}
          onClose={() => setShowEmailTemplates(false)}
        />
      )}
      {showPublishModal && (
        <PublishResponse
          show={showPublishModal}
          review={review}
          draft={draft}
          updateStarted={updateStarted}
          onCancel={() => setShowPublishModal(false)}
          onPublishReview={handlePublishResponse}
        />
      )}
      <ClearTemplateConfirm open={clearTemplateConfirmationOpen} onConfirm={clearTemplate} onClose={()=>{setClearTemplateConfirmationOpen(false)}}/>

    </>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review.review,
    updateStarted: state.review.updateStarted,
    actionUpdateStarted: state.review.actionUpdateStarted,
    displayReasonComment: state.review.displayReasonComment,
    actionUpdateFor: state.review.actionUpdateFor,
    saveCompleted: state.review.saveCompleted,
    activeFolderId: state.reviews.activeFolderId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, status) => dispatch(addNotificationAction(text, status)),
    saveDraftRequest: () => {
      dispatch(reviewSaveDraftStartedAction())
    },
    saveActionRequest: actionId => {
      dispatch(reviewSaveActionStartedAction(actionId))
    },
    saveReviewCompleted: (review, saveAction) => {
      dispatch(reviewSaveReviewCompletedAction(review, saveAction))
    },
    handleReviewDisplayReasonComment: display => {
      dispatch(reviewDisplayReasonCommentAction(display))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewResponse)
