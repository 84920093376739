import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ReviewServiceFactory, { RadarServices } from '../../../../services'
import FilterModal from './FilterModal'

function ReponseTemplates({ show, onClose, onTemplateItemSelected, review }) {
  const replacementVariables = {
    '[ORIGINAL_REVIEW]': review.reviewText,
    '[PROPOSED_RESPONSE]': review.responseText,
    '[CLIENT_NAME]': review.client ? review.client.clientName : '[CLIENT_NAME]',
    '[SITE]': review.reviewSite,
    '[DATE]': moment().format('MMMM Do YYYY'),
    '[LINK]': review.reviewUrl,
    '[PUBLISHER]': review.reviewerName ?? '[REVIEWER]',
  }

  const [templates, setTemplates] = useState([])
  const [useTemplateStarted,setUseTemplateStarted] = useState(false)
  const [fetchStarted, setFetchStarted] = useState(false)

  const replaceTemplateVariables = template => {
    let keys = Object.keys(replacementVariables)
    return keys.reduce((updatedTemplate, key) => {
      let replace = `\\${key.slice(0, key.length - 1)}\\]`
      return updatedTemplate.replace(new RegExp(replace, 'g'), replacementVariables[key] || '')
    }, template)
  }

  const fetchTemplates = async () => {
    let service = ReviewServiceFactory.create(RadarServices.ResponseTemplates),
      result = await service.getTemplatesForClient(review.client.clientId)
    if (result.Ok) {
      setTemplates(result.Content.templates)
    } else {
      setTemplates([])
    }

    setFetchStarted(false)
  }

  const usageTemplate = async (text,id) => {
    setFetchStarted(true)
    let service = ReviewServiceFactory.create(RadarServices.ResponseTemplates),
    result = await service.usageTemplate(id,review.client.clientId)
    setFetchStarted(false)
    onTemplateItemSelected(replaceTemplateVariables(text),id)
  }

  const handleTemplateItemSelected = (text,id) => {
    usageTemplate(text,id)
  }

  useEffect(() => {
    if (show) {
      setFetchStarted(true)
      fetchTemplates()
    }
  }, [show])
  return (
    <>
      <FilterModal
        id='response-template'
        show={show}
        title='Choose a Response Template'
        noResultsText='No responses available'
        fetchStarted={fetchStarted}
        fetchTemplates={fetchTemplates}
        templates={templates}
        onTemplateItemSelected={handleTemplateItemSelected}
        onClose={onClose}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review.review,
  }
}

export default connect(mapStateToProps, null)(ReponseTemplates)
